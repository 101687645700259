// material-ui
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

// styles
const LoaderWrapper = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1301,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

// ==============================|| LOADER ||============================== //

const Spinner = () => (
    <LoaderWrapper>
        <CircularProgress color="primary" />
    </LoaderWrapper>
);

export default Spinner;
