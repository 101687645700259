import React from 'react';
import useAuth from 'hooks/useAuth';
import { Typography } from '@mui/material';
import { useGetClientLogoQuery } from 'store/services/client';

function ClientLogo() {
    const { user } = useAuth();
    const { client } = useAuth();

    const {
        data: logo,
        isError,
        isFetching,
        isLoading
    } = useGetClientLogoQuery(
        {
            ...(user?.client_id && { client_id: user.client_id })
        },
        { skip: !user?.client_id }
    );

    return (
        <>
            {logo && !isError && !(isFetching || isLoading) ? (
                <img style={{ maxWidth: 100, maxHeight: 50 }} src={logo} alt="client logo" />
            ) : (
                !user?.is_superuser && (
                    <Typography color="white" variant="h4">
                        {client?.name}
                    </Typography>
                )
            )}
        </>
    );
}

export default ClientLogo;
