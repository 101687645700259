import CryptoJS from 'crypto-js';

const key = process.env.REACT_APP_ENCRIPTION_KEY;

export const encriptId = (txt = '') => {
    if (!txt) return '';
    const encriptedTxt = CryptoJS.AES.encrypt(txt.toString(), key);
    return encodeURIComponent(encriptedTxt.toString());
};

export const decriptId = (txt = '') => {
    if (!txt) return '';
    const decryptedBytes = CryptoJS.AES.decrypt(decodeURIComponent(txt), key);
    return decryptedBytes.toString(CryptoJS.enc.Utf8);
};
