/* eslint-disable no-nested-ternary */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Checkbox, Chip, TextField } from '@mui/material';
import useSnackbar from 'hooks/useSnackbar';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    inputDisable: {
        '& .MuiAutocomplete-input': {
            display: 'none'
        }
    }
}));

const AsyncDropdown = forwardRef(
    (
        {
            multi,
            label,
            payload,
            handler,
            limitTags,
            debounceTime,
            defaultValue,
            OptionAcessor = 'name',
            SearchAcessor = 'name',
            onChange,
            excludeList,
            ...rest
        },
        ref
    ) => {
        let debounceTimer;

        // const classes = useStyles();
        const { notify } = useSnackbar();
        const { payload: pd, skip: sk, ...rtkOpts } = payload;
        const [value, setValue] = useState(defaultValue || (multi ? [] : null));
        const [searchParam, setSearchParam] = useState('');

        useEffect(() => {
            if (defaultValue) {
                setValue(defaultValue);
            }
        }, [defaultValue]);

        let cPayload = { ...pd };

        if (cPayload?.filters) {
            cPayload = {
                ...cPayload,
                filters: { ...cPayload.filters, [SearchAcessor]: searchParam }
            };
        } else {
            cPayload = { ...cPayload, [SearchAcessor]: searchParam };
        }

        const { data, isFetching, isError } = handler(cPayload, {
            ...rtkOpts,
            skip: !searchParam || sk
        });

        const handleChange = (e, val) => {
            setValue(val);
            if (onChange) {
                onChange(val);
            }
        };

        const handleSearchParamChange = (e) => {
            clearTimeout(debounceTimer);

            debounceTimer = setTimeout(() => {
                if (e?.target?.value) {
                    setSearchParam(e.target.value);
                }
            }, debounceTime || 500);
        };

        const handleReset = () => {
            setValue(multi ? [] : null);
        };

        useImperativeHandle(ref, () => ({
            handleReset
        }));

        if (isError) {
            notify({ message: 'Error fetching dropdown list', severity: 'warning' });
        }

        // useEffect(
        //     () => {
        //         if (onChange) {
        //             onChange(value);
        //         }
        //     },
        //     // eslint-disable-next-line react-hooks/exhaustive-deps
        //     [value]
        // );

        return (
            <>
                <Autocomplete
                    ref={ref}
                    multiple={multi}
                    loading={isFetching}
                    disableCloseOnSelect={multi}
                    getOptionLabel={(option) => option[OptionAcessor]}
                    options={excludeList ? data?.fields.filter((item) => !excludeList.includes(item.id)) || [] : data?.fields || []}
                    value={value}
                    onChange={handleChange}
                    onInputChange={handleSearchParamChange}
                    includeInputInList={false}
                    {
                        // {...(multi && value?.length > 0 && { className: classes.inputDisable })}
                        ...(limitTags && {
                            renderTags: (value) => {
                                const numTags = value?.length;

                                return (
                                    <>
                                        {value?.slice(0, limitTags).map((option, index) => (
                                            <Chip
                                                variant="outlined"
                                                sx={{
                                                    mr: 1,
                                                    maxWidth: 100,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}
                                                size="medium"
                                                key={index}
                                                label={option[SearchAcessor]}
                                            />
                                        ))}
                                        <span>{numTags > limitTags && `+${numTags - limitTags} more`}</span>
                                    </>
                                );
                            }
                        })
                    }
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            {multi && <Checkbox size="small" checked={selected} color="primary" sx={{ mr: 1 }} />}
                            {option[OptionAcessor]}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            fullWidth
                            label={label || 'Search ...'}
                            // placeholder={`Type to search ${label || '...'} `}
                            InputProps={{
                                ...params.InputProps
                            }}
                            {...params}
                        />
                    )}
                    {...(multi && {
                        sx: {
                            '& .MuiInputBase-root': {
                                height: '60px',
                                overflowY: 'auto',
                                border: (theme) => `1px solid ${theme.palette.grey[400]}`
                            },
                            '& fieldset': {
                                height: '58px',
                                border: 'none'
                            }
                        }
                    })}
                    {...rest}
                />
            </>
        );
    }
);

AsyncDropdown.propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    payload: PropTypes.object,
    multi: PropTypes.bool,
    filter: PropTypes.bool,
    limitTags: PropTypes.number,
    label: PropTypes.string,
    OptionAcessor: PropTypes.string,
    SearchAcessor: PropTypes.string,
    debounceTime: PropTypes.number,
    rtkOpts: PropTypes.object,
    handler: PropTypes.func,
    onChange: PropTypes.func
};

export default AsyncDropdown;
