// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import snackbarReducer from './slices/snackbar';
import customerReducer from './slices/customer';
import calendarReducer from './slices/calendar';
import userReducer from './slices/user';
import cartReducer from './slices/cart';
import menuReducer from './slices/menu';
import authReducer from './slices/auth';
import patientReducer from './slices/patient';
import dashboardReducer from './slices/dashboard';
import { api } from './services/api';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    snackbar: snackbarReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    customer: customerReducer,
    calendar: calendarReducer,
    user: userReducer,
    menu: menuReducer,
    patient: patientReducer,
    dashboard: dashboardReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        state = {};
    }

    return reducer(state, action);
};

export default rootReducer;
