import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    selectedItem: [],
    drawerOpen: false,
    drawerType: 'permanent'
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.selectedItem = action.payload;

            if (action.payload[0] === 'dashboard') {
                state.drawerType = 'permanent';
            } else {
                state.drawerType = 'temporary';
            }
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload;
        },

        onHover(state, action) {
            if (state.drawerType !== 'permanent') {
                state.drawerOpen = action.payload;
            }
        },

        changeDrawerType(state, action) {
            state.drawerType = action.payload;
        }
    }
});

export default menu.reducer;

export const { activeItem, openDrawer, changeDrawerType, onHover } = menu.actions;
