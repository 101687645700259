import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Loader from 'ui-component/Loader';
import { useRenewTokenMutation, useGetUserMutation } from 'store/services/auth';
import { useDispatch } from 'react-redux';
import { setCredentials } from 'store/slices/auth';
import jwtDecode from 'jwt-decode';
import { setSession } from 'store/services/api';

const verifyToken = (token) => {
    if (!token) {
        return false;
    }
    const decoded = jwtDecode(token);

    return decoded.exp > Date.now() / 1000;
};

export const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();
    const [isInitialized, setIsInitialized] = useState(false);
    const [renewToken, { isLoading }] = useRenewTokenMutation();
    const [getUser, { isLoading: isUserLoading }] = useGetUserMutation();

    useEffect(() => {
        const init = async () => {
            try {
                const refreshToken = localStorage.getItem('refreshToken');
                if (verifyToken(refreshToken)) {
                    const accessToken = localStorage.getItem('accessToken');
                    if (verifyToken(accessToken)) {
                        const client = localStorage.getItem('client');
                        dispatch(
                            setCredentials({
                                isLoggedIn: true,
                                token: accessToken,
                                client: client === 'undefined' ? null : JSON.parse(client)
                            })
                        );
                        await getUser({
                            client_id: JSON.parse(client).id
                        });
                        setIsInitialized(true);
                    } else {
                        const payload = {
                            refresh: refreshToken
                        };
                        const res = await renewToken(payload).unwrap();
                        setSession(res.access, res.refresh);
                        const client = localStorage.getItem('client');
                        dispatch(
                            setCredentials({
                                isLoggedIn: true,
                                client: client === 'undefined' ? null : JSON.parse(client)
                            })
                        );
                        await getUser();
                        setIsInitialized(true);
                    }
                } else {
                    setIsInitialized(true);
                    setSession(null, null);
                }
            } catch (err) {
                console.error(err);
                setIsInitialized(true);
                setSession(null, null);
            }
        };

        init();
    }, []);

    if (!isInitialized || isLoading || isUserLoading) {
        return <Loader />;
    }

    return children;
};

AuthProvider.propTypes = {
    children: PropTypes.node
};

export default AuthProvider;
