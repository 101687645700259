import React, { memo, useEffect, useRef, useState } from 'react';
import { Close, DoneAll } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Drawer, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useInfiniteNotificationsQuery, useUpdateNotificationsMutation } from 'store/services/notification';
import { convertToLocal24 } from 'utils/time-conversion';
import { useTheme } from '@mui/styles';
import useAuth from 'hooks/useAuth';
import { GLOBAL_DATE_FORMAT } from 'constant';

function CNotificationCard({ id, message, date, final, onClick }) {
    const theme = useTheme();

    const handleClick = () => {
        onClick(id);
    };

    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="center" onClick={handleClick} sx={{ cursor: 'pointer' }}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textTransform: 'capitalize',
                            height: 80,
                            width: 80,
                            borderRadius: 80,
                            background: theme.palette.grey[300],
                            fontSize: 30,
                            fontWeight: 600,
                            flexShrink: 0
                        }}
                    >
                        {message?.charAt(0) || 'N'}
                    </Box>
                    <Stack spacing={1}>
                        <Typography>{message}</Typography>
                        <Typography variant="subtitle2">{convertToLocal24(date, GLOBAL_DATE_FORMAT)}</Typography>
                    </Stack>
                </Stack>
            </Stack>
            {!final && <Divider />}
        </>
    );
}

function NotificationDrawer({ open, onClose, onClick }) {
    const { user } = useAuth();
    const observerRef = useRef(null);
    const [page, setPage] = useState(1);

    const [readNotification] = useUpdateNotificationsMutation();

    const { data: notifications, refetch } = useInfiniteNotificationsQuery(
        {
            filters: {
                ...(user?.client_id && { client_id: user.client_id })
            },
            pagination: { current_page: page }
        },
        { skip: !open }
    );

    const handleMarkAllRead = () => {
        const unseen = notifications?.fields?.filter((n) => !n.is_read);
        readNotification({
            ...(unseen?.length > 0 && {
                notification_ids: unseen?.map((n) => n.id) || []
            })
        });
    };

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        };

        const handleIntersect = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && page < notifications?.pagination?.last_page) {
                    setPage((page) => page + 1);
                }
            });
        };

        observerRef.current = new IntersectionObserver(handleIntersect, options);

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications]);

    useEffect(() => {
        // refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Drawer open={open} anchor="right" onClose={onClose}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 500,
                    height: '100vh',
                    px: 1,
                    mt: 9
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ height: 60, px: 1 }}>
                    <Typography variant="h4">Notifications</Typography>
                    <Tooltip title="Close notification drawer">
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Tooltip>
                </Stack>
                <Divider />
                <Stack spacing={1} sx={{ flex: 1, overflowY: 'auto', p: 1 }}>
                    {notifications?.fields?.map((n, idx) => (
                        <CNotificationCard
                            id={n.id}
                            key={n.id}
                            message={n.message || ''}
                            date={n.created_on}
                            final={idx === notifications?.fields?.length - 1}
                            onClick={onClick}
                        />
                    ))}
                    <div ref={(node) => node && observerRef.current.observe(node)} />
                </Stack>
                <Divider />
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 1 }}>
                    <Button variant="text" onClick={handleMarkAllRead}>
                        <DoneAll sx={{ fontSize: 24, mr: 1 }} />
                        Mark all as read
                    </Button>
                </Stack>
            </Box>
        </Drawer>
    );
}

CNotificationCard.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    message: PropTypes.string,
    date: PropTypes.string,
    final: PropTypes.bool,
    onClick: PropTypes.func
};

NotificationDrawer.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onClick: PropTypes.func
};

export default memo(NotificationDrawer);
