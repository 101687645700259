import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { resetStore } from 'store/actions';
import { logout, setCredentials } from 'store/slices/auth';
import { resetDashboardState } from 'store/slices/dashboard';
import { openSnackbar } from 'store/slices/snackbar';

export const setSession = (accessToken, refreshToken) => {
    if (accessToken && refreshToken) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
    } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    }
};

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        // const token = getState().auth.token;
        const token = localStorage.getItem('accessToken');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        console.log({ result });
        // const notCurrentUserCode = ['user_inactive', 'token_deleted'];

        if (result.error.data.code === 'token_not_valid') {
            // try to get a new token
            const payload = {
                refresh: localStorage.getItem('refreshToken')
            };
            const refreshResult = await baseQuery(
                {
                    url: '/api/user/login/refresh/',
                    method: 'POST',
                    body: payload
                },
                api,
                extraOptions
            );

            if (refreshResult.data) {
                // store the new token
                setSession(refreshResult.data.access, refreshResult.data.refresh);
                api.dispatch(
                    setCredentials({
                        token: refreshResult.data.access
                    })
                );

                // retry the initial query
                result = await baseQuery(args, api, extraOptions);
            } else {
                setSession(null, null);
                localStorage.removeItem('client');

                api.dispatch(logout());
                api.dispatch(resetStore());
                api.dispatch(resetDashboardState());

                api.dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Your session has expired. Please login again.',
                        variant: 'alert',
                        alert: {
                            variant: 'filled',
                            color: 'error'
                        },
                        close: false
                    })
                );
            }
        } else {
            setSession(null, null);
            localStorage.removeItem('client');

            api.dispatch(logout());
            api.dispatch(resetStore());
            api.dispatch(resetDashboardState());

            api.dispatch(
                openSnackbar({
                    open: true,
                    message: 'Unauthorized Access. Please login again.',
                    variant: 'alert',
                    alert: {
                        variant: 'filled',
                        color: 'error'
                    },
                    close: false
                })
            );
        }
    }
    return result;
};

// const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
    /**
     * `reducerPath` is optional and will not be required by most users.
     * This is useful if you have multiple API definitions,
     * e.g. where each has a different domain, with no interaction between endpoints.
     * Otherwise, a single API definition should be used in order to support tag invalidation,
     * among other features
     */
    reducerPath: 'api',
    /**
     * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
     */
    baseQuery: baseQueryWithReauth,
    /**
     * Tag types must be defined in the original API definition
     * for any tags that would be provided by injected endpoints
     */
    tagTypes: [
        // patients
        'PatientList',
        'PatientDetail',
        'BillingDetails',
        'DocumentList',
        'EmployeeDetails',
        'RolesWithPermissions',
        'InsuranceList',
        'RelationsList',
        'DocumentDownload',

        // settings
        'ClientList',
        'LocationList',
        'EmployeeList',
        'ActivitySetupList',
        'ActivitySetupetails',
        'ClassroomList',
        'ClassroomDetails',
        'GoalDetails',
        'GoalList',

        // schedule
        'SessionList',
        'SessionDetails',
        'EventList',
        'EventDetails',
        'ScheduleList',

        // therapy
        'EvaluationDetails',

        'EvaluationList',
        'ProgressNoteList',
        'ProgressNoteDetails',
        'UserDetails',
        'ClientLogo',
        'GoalHistory',
        'TherapyProgress',
        'ICDList',
        'ScriptIssueList',
        'TherapistList',
        'DashboardEvaluationList',
        'DashboardTreatmentList',
        'PhysicianList',
        'MasterTracker',
        'TotalPatientCount',
        'AveragePatientCount',
        'BillingClaims',
        'NewPatientCount',
        'EmployeeServices',
        'BillingDownload',
        'GoalsMastered',
        'BatchList',
        'TherapistReport',
        'AttendanceCount',
        'SessionStudentList',
        'ScheduleList',
        'InventoryKitList',
        'KitItemsList',
        'ContractList',
        'ContractServices',
        'ContractDetail',

        // notification
        'NotificationList',
        'NotificationListInfinite',
        'ClientLogo',
        'LocationTypeList',
        'ServiceTypesList',
        'ServiceTypeDetail',
        'LicenseTypeList',

        // reports
        'UnsignedReportsList',
        'MasterTrackerDownload',
        'ExpirationReportDownload',
        // mail
        'EmailTypeList',
        'EmailBucketList',
        'EmailList',
        // billing
        'ClaimDataList',
        'LogsList',
        // dashboard
        'PhysicianPatientCount',
        'PhysicianPrescriptionCount',

        'TreatmentPlanList',
        'PrescriptionList',
        'CenterReport',
        'CenterReportDetail',
        'CenterReportDownload',
        'ClientInvoice',
        'EarlyDismissalReport',
        'EarlyDismissalReportDownload',
        'PatientInvoiceHistory',
        'PatientAccountDetails',
        'PatientRecords',
        'NewPayrollReportList',
        'GeneratePatientInvoice',
        'ListPreviousInvoices',

        'EmployeeServicesList'
    ],
    /**
     * This api has endpoints injected in adjacent files,
     * which is why no endpoints are shown below.
     * If you want all endpoints defined in the same file, they could be included here instead
     */
    endpoints: () => ({}),

    // keep data in cache for 30 minutes
    keepUnusedDataFor: 30 * 60
});

// export const enhancedApi = api.enhanceEndpoints({
//   endpoints: () => ({
//     getPost: () => 'test',
//   }),
// })
