import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';

const useSnackbar = () => {
    const dispatch = useDispatch();

    const notify = ({ message, severity }) => {
        dispatch(
            openSnackbar({
                open: true,
                message,
                variant: 'alert',
                alert: {
                    variant: 'filled',
                    color: severity
                },
                close: false
            })
        );
    };

    const notifyError = ({ error, severity }) => {
        if (error.data) {
            error.data.message?.forEach((message) => {
                notify({ message, severity: 'error' });
            });
        } else {
            notify({
                message: 'Something went wrong',
                severity: severity || 'error'
            });
        }
    };

    return { notify, notifyError };
};

export default useSnackbar;
