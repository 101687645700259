import { createSlice } from '@reduxjs/toolkit';
import { defaultLayout } from 'views/dashboard/util/layout';

const initialState = {
    error: null,
    activeClient: null,
    layout: defaultLayout,
    dailyTherapyTreatmentTable: {
        show: false,
        data: {
            title: 'Evaluation',
            timeVariant: 'today',
            cols: [],
            rows: []
        }
    },
    dailyTherapyTreatmentGraph: {
        show: false,
        data: {
            title: 'Evaluation',
            timeVariant: 'today',
            options: { type: 'bar', stacked: 'false' },
            series: []
        }
    }
};

const slice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setDashboardLayout(state, action) {
            state.layout = action.payload;
        },
        toggleDailyTherapyTreatmentTable(state, action) {
            state.dailyTherapyTreatmentTable.show = action.payload;
        },
        toggleDailyTherapyTreatmentGraph(state, action) {
            state.dailyTherapyTreatmentGraph.show = action.payload;
        },
        setDailyTherapyTreatmentTableData(state, action) {
            state.dailyTherapyTreatmentTable.data = action.payload;
        },
        setDailyTherapyTreatmentGraphData(state, action) {
            state.dailyTherapyTreatmentGraph.data = action.payload;
        },
        resetDashboardState(state) {
            state = initialState;
        },
        setActiveClient(state, action) {
            state.activeClient = action.payload;
        }
    }
});

export default slice.reducer;
export const {
    setDashboardLayout,
    toggleDailyTherapyTreatmentTable,
    toggleDailyTherapyTreatmentGraph,
    setDailyTherapyTreatmentTableData,
    setDailyTherapyTreatmentGraphData,
    resetDashboardState,
    setActiveClient
} = slice.actions;
