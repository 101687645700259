// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
// import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

// auth provider
import AuthProvider from 'contexts/AuthContext';
import { GlobalStateProvider } from 'contexts/GlobalStateProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import ErrorBountry from 'components/error/ErrorBountry';
import { ConfirmationProvider } from 'components/reusable/ConfirmationModal';
// import Error from 'views/pages/maintenance/Error';
// import GlobalLoader from 'components/common/GlobalLoader';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'bootstrap/dist/css/bootstrap.min.css';
import useIdleTimeout from 'hooks/useIdleTimout';
import useAuth from 'hooks/useAuth';
import { IDLE_TIMEOUT_DURATION } from 'constant';
import IdleDetector from 'components/common/IdleDetector';

// ==============================|| APP ||============================== //

const App = () => (
    <ErrorBountry>
        <ThemeCustomization>
            <ConfirmationProvider>
                <IdleDetector>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <GlobalStateProvider>
                            <Locales>
                                <NavigationScroll>
                                    <AuthProvider>
                                        <Routes />
                                        <Snackbar />
                                    </AuthProvider>
                                </NavigationScroll>
                            </Locales>
                        </GlobalStateProvider>
                    </LocalizationProvider>
                </IdleDetector>
                {/* <GlobalLoader /> */}
            </ConfirmationProvider>
        </ThemeCustomization>
    </ErrorBountry>
);

export default App;
