import { api } from './api';

const getFormData = (object) =>
    Object.keys(object).reduce((formData, key) => {
        if (key.slice(-2) === '[]') {
            object[key].forEach((item) => formData.append(key, item));
        } else formData.append(key, object[key]);
        return formData;
    }, new FormData());

export const patientApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getPatientLandingList: builder.query({
            query: (payload) => ({
                url: 'api/patient/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'landinglist'
                }
            })
        }),
        getPatientDropDown: builder.query({
            query: (payload) => ({
                url: 'api/patient/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list_patient_dropdown'
                }
            }),
            providesTags: ['PatientList']
        }),
        getPatientList: builder.query({
            query: (payload) => ({
                url: 'api/patient/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            }),
            providesTags: ['PatientList']
        }),
        getPatientDetails: builder.query({
            query: (body) => ({
                url: 'api/patient/',
                method: 'POST',
                body,
                headers: {
                    SUBTYPE: 'DETAIL'
                }
            }),
            providesTags: ['PatientDetail']
        }),
        addUpdatePatient: builder.mutation({
            query: (payload) => ({
                url: 'api/patient/',
                method: 'POST',
                body: getFormData(payload),
                headers: {
                    SUBTYPE: payload.id ? 'UPDATE' : 'CREATE'
                }
            }),
            invalidatesTags: ['PatientList', 'PatientDetail', 'DocumentList']
        }),
        changePatientStatus: builder.mutation({
            query: (payload) => ({
                url: 'api/patient/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'status'
                }
            }),
            invalidatesTags: ['PatientList']
        }),

        // general info
        getLocations: builder.query({
            query: (body) => ({
                url: 'api/location/',
                method: 'POST',
                body,
                headers: {
                    SUBTYPE: 'LIST'
                }
            })
        }),
        getCampuses: builder.query({
            query: (body) => ({
                url: 'api/location/campus/',
                method: 'POST',
                body,
                headers: {
                    SUBTYPE: 'LIST'
                }
            })
        }),

        // Documents
        getParentDocumentType: builder.query({
            query: () => ({
                url: 'api/core/documenttype/',
                method: 'POST',
                body: {},
                headers: {
                    SUBTYPE: 'parents'
                }
            }),
            providesTags: ['DocumentList']
        }),
        getDocumentType: builder.query({
            query: () => ({
                url: 'api/core/documenttype/',
                method: 'POST',
                body: {},
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['DocumentList']
        }),
        getDocumentList: builder.query({
            query: (payload) => ({
                url: 'api/patient/document/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['DocumentList']
        }),
        uploadDocument: builder.mutation({
            query: (payload) => ({
                url: 'api/patient/document/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'CREATE'
                }
            }),
            invalidatesTags: ['DocumentList', 'DocumentDownload']
        }),
        deleteDocument: builder.mutation({
            query: (payload) => ({
                url: 'api/patient/document/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'DELETE'
                }
            }),
            invalidatesTags: ['DocumentList', 'DocumentDownload']
        }),
        uploadForm: builder.mutation({
            query: (payload) => ({
                url: 'api/patient/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'uploadformdoc'
                }
            }),
            invalidatesTags: ['DocumentList', 'DocumentDownload']
        }),

        // insurance
        getInsuranceList: builder.query({
            query: (payload) => ({
                url: 'api/patient/insurance/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['InsuranceList']
        }),
        addUpdateInsurance: builder.mutation({
            query: (payload) => ({
                url: 'api/patient/insurance/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: payload.get('id') ? 'UPDATE' : 'CREATE'
                }
            }),
            invalidatesTags: ['InsuranceList']
        }),
        deleteInsurance: builder.mutation({
            query: (payload) => ({
                url: 'api/patient/insurance/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'DELETE'
                }
            }),
            invalidatesTags: ['InsuranceList']
        }),

        // preauth

        addUpdatePreAuth: builder.mutation({
            query: (payload) => ({
                url: 'api/patient/preauth/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'addupdate'
                }
            }),
            invalidatesTags: ['preAuthList', 'PatientHistory']
        }),

        deletePreAuth: builder.mutation({
            query: (payload) => ({
                url: 'api/patient/preauth/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'delete'
                }
            }),
            invalidatesTags: ['preAuthList', 'PatientHistory']
        }),

        getPreAuthList: builder.query({
            query: (payload) => ({
                url: 'api/patient/preauth/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            }),
            providesTags: ['preAuthList']
        }),

        // preauth unit

        addUpdatePaUnit: builder.mutation({
            query: (payload) => ({
                url: 'api/patient/preauth/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'addupdateunit'
                }
            }),
            invalidatesTags: ['paUnitList', 'PatientHistory']
        }),

        deletePaUnit: builder.mutation({
            query: (payload) => ({
                url: 'api/patient/preauth/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'unitdelete'
                }
            }),
            invalidatesTags: ['paUnitList', 'PatientHistory']
        }),

        getPaUnitList: builder.query({
            query: (payload) => ({
                url: 'api/patient/preauth/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'unitlist'
                }
            }),
            providesTags: ['paUnitList']
        }),

        getPaRmngUnitList: builder.query({
            query: (payload) => ({
                url: 'api/patient/preauth/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'unitlistdate'
                }
            }),
            providesTags: ['paUnitList']
        }),

        // billing details
        getServicesByLocation: builder.query({
            query: (body) => ({
                url: 'api/location/',
                method: 'POST',
                body,
                headers: {
                    SUBTYPE: 'DETAIL'
                }
            })
        }),
        getModifiders: builder.query({
            query: () => ({
                url: 'api/core/modifier/',
                method: 'POST',
                body: {},
                headers: {
                    SUBTYPE: 'LIST'
                }
            })
        }),
        getPayerCategory: builder.query({
            query: () => ({
                url: 'api/core/payer-category/',
                method: 'POST',
                body: {},
                headers: {
                    SUBTYPE: 'LIST'
                }
            })
        }),
        getPayerType: builder.query({
            query: () => ({
                url: 'api/core/payer-type/',
                method: 'POST',
                body: {},
                headers: {
                    SUBTYPE: 'LIST'
                }
            })
        }),

        getBillingDetails: builder.query({
            query: (payload) => ({
                url: 'api/patient/billing-details/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['BillingDetails']
        }),
        createBillingDetail: builder.mutation({
            query: (payload) => ({
                url: 'api/patient/billing-details/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: payload.id ? 'update' : 'create'
                }
            }),
            invalidatesTags: ['BillingDetails']
        }),

        deleteBillingDetail: builder.mutation({
            query: (payload) => ({
                url: 'api/patient/billing-details/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'delete'
                }
            }),
            invalidatesTags: ['BillingDetails']
        }),
        checkBillingDetail: builder.mutation({
            query: (payload) => ({
                url: 'api/patient/billing-details/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'check'
                }
            })
            // invalidatesTags: ['BillingDetails']
        }),
        getPreviousBillingDetail: builder.query({
            query: (body) => ({
                url: 'api/patient/billing-details/',
                method: 'POST',
                body,
                headers: {
                    SUBTYPE: 'DETAIL'
                }
            })
        }),
        // history
        getPatientHistory: builder.query({
            query: (payload) => ({
                url: 'api/patient/activity/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            // serializeQueryArgs: ({ endpointName }) => endpointName,
            // merge: (currentCache, newItems, { arg }) => {
            //     console.log(arg);
            //
            //     const page = arg?.pagination?.current_page;
            //
            //     const temp = {
            //         fields: page === 1 ? currentCache?.fields : [...currentCache?.fields, ...newItems?.fields],
            //         error: newItems.error,
            //         message: newItems.message,
            //         pagination: newItems.pagination ? newItems.pagination : currentCache.pagination
            //     };
            //
            //     return temp;
            // },
            // forceRefetch({ currentArg, previousArg }) {
            //     return currentArg !== previousArg;
            // },
            invalidatesTags: ['PatientHistory']
        }),
        setInsuranceStatus: builder.mutation({
            query: (payload) => ({
                url: 'api/patient/insurance/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'STATUS'
                },
                invalidatesTags: ['InsuranceList']
            })
        }),
        getRelationsList: builder.query({
            query: (payload) => ({
                url: 'api/core/family-relation/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['RelationsList']
        }),
        getEvalProgressList: builder.query({
            query: (body) => ({
                url: 'api/therapy/',
                method: 'POST',
                body,
                headers: {
                    SUBTYPE: 'EVALPROGRESSLIST'
                }
            })
        }),
        getPrescriptionPatient: builder.query({
            query: (body) => ({
                url: 'api/patient/prescription/',
                method: 'POST',
                body,
                headers: {
                    SUBTYPE: 'patient_details'
                }
            }),
            providesTags: ['PatientDetail']
        })
    })
});

export const {
    useGetPatientLandingListQuery,
    useGetPatientDropDownQuery,
    useGetPatientListQuery,
    useLazyGetPatientListQuery,
    useGetPatientDetailsQuery,
    useAddUpdatePatientMutation,
    useChangePatientStatusMutation,

    useGetLocationsQuery,
    useGetCampusesQuery,

    useGetParentDocumentTypeQuery,
    useGetDocumentTypeQuery,
    useGetDocumentListQuery,
    useUploadDocumentMutation,
    useDeleteDocumentMutation,
    useUploadFormMutation,

    useGetInsuranceListQuery,
    useAddUpdateInsuranceMutation,
    useDeleteInsuranceMutation,

    useAddUpdatePreAuthMutation,
    useDeletePreAuthMutation,
    useGetPreAuthListQuery,

    useAddUpdatePaUnitMutation,
    useDeletePaUnitMutation,
    useGetPaUnitListQuery,
    useGetPaRmngUnitListQuery,

    useGetServicesByLocationQuery,
    useGetModifidersQuery,
    useGetPayerCategoryQuery,
    useGetPayerTypeQuery,
    useCreateBillingDetailMutation,
    useGetBillingDetailsQuery,
    useDeleteBillingDetailMutation,
    useCheckBillingDetailMutation,
    useGetPreviousBillingDetailQuery,

    useGetPatientHistoryQuery,
    useSetInsuranceStatusMutation,
    useGetRelationsListQuery,

    useGetEvalProgressListQuery,
    useLazyGetEvalProgressListQuery,

    useGetPrescriptionPatientQuery
} = patientApi;
