import React, { Component } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import SendErrorLog from './SendErrorLog';
// import { Link } from 'react-router-dom';
// import { DASHBOARD_PATH } from 'config';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, info: null, renderCounter: 0 };
        this.resetState = this.resetState.bind(this);
    }

    // static getDerivedStateFromError(error) {
    //     return { hasError: true };
    //   }

    componentDidCatch(error, info) {
        console.log('Error Boundary', { error, info });

        // Reload the page if a chunk load error occurs
        const isChunkLoadError = /ChunkLoadError/i.test(error?.stack);
        if (isChunkLoadError) {
            window.location.reload();
        } else {
            // Catch errors and update state
            this.setState((prevState) => ({
                hasError: true,
                error,
                info,
                renderCounter: prevState.renderCounter + 1
            }));
        }
    }

    resetState() {
        this.setState({ hasError: false, error: null, info: null, renderCounter: 0 });
    }

    render() {
        const { hasError, error, info, renderCounter } = this.state;
        const { children } = this.props;

        const canRenderChild = renderCounter < 2;

        if (hasError) {
            // Render fallback UI
            // return <UnexpectedError />;

            return (
                <>
                    {canRenderChild && children}
                    <ErrorDialog canRenderChild={canRenderChild} resetError={this.resetState} />
                    <SendErrorLog hasError={hasError} error={error} info={info} renderCounter={renderCounter} />
                </>
            );
        }

        // Render children if no errors
        return children;
    }
}

export default ErrorBoundary;

function ErrorDialog({ canRenderChild, resetError }) {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        resetError();
    };

    // const handleReload = () => {
    //     window.location.reload();
    // };

    const returnToLastPage = () => {
        navigate(-1);
        resetError();
    };

    return (
        <Dialog
            open={open}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">An Unexpected Error Occurred</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">Sorry for the inconvenience. Please try again later.</DialogContentText>
            </DialogContent>
            <DialogActions>
                {canRenderChild ? (
                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                ) : (
                    <Button onClick={returnToLastPage} autoFocus>
                        Return to Last Page
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
