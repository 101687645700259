import React, { createContext, useState } from 'react';

export const GlobalState = createContext();

export const GlobalStateProvider = (props) => {
    const [clientId, setClientId] = useState([]);
    const [locationId, setLocationId] = useState([]);
    const [employeeId, setEmployeeId] = useState([]);
    const [patientData, setPatientData] = useState([]);
    const [TreeData, setTreeData] = useState([]);
    const [patientFileViewModal, setPatientFileViewModal] = useState(false);
    const [patientFileUploadModal, setPatientFileUploadModal] = useState(false);
    const [patientDocInfo, setPatientDocInfo] = useState(0);
    const [patientGeneralData, setpatientGeneralData] = React.useState({});
    const [generalDetailsInfoData, setGeneralDetailsInfoData] = React.useState({});

    return (
        <GlobalState.Provider
            value={{
                clientId,
                setClientId,
                locationId,
                setLocationId,
                employeeId,
                setEmployeeId,
                patientData,
                setPatientData,
                TreeData,
                setTreeData,
                patientFileViewModal,
                setPatientFileViewModal,
                patientDocInfo,
                setPatientDocInfo,
                patientFileUploadModal,
                setPatientFileUploadModal,
                patientGeneralData,
                setpatientGeneralData,
                generalDetailsInfoData,
                setGeneralDetailsInfoData
            }}
        >
            {props.children} {/*              eslint-disable-line */}
        </GlobalState.Provider>
    );
};
