import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isLoggedIn } = useAuth();
    // const location = useLocation();
    // const from = location.state?.from?.pathname || DASHBOARD_PATH;

    return !isLoggedIn ? children : <Navigate to={DASHBOARD_PATH} replace />;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
