import React, { createContext, useContext, useState } from 'react';
import { Button, DialogTitle, Dialog, DialogActions, DialogContent, DialogContentText, Link as MuiLink } from '@mui/material';

function ConfirmationModal(props) {
    const { open, handleClose, handleConfirm, title, description, sx, confirmText, cancelText } = props;

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={sx}>{description}</DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={handleConfirm} autoFocus>
                    Yes
                </Button>
                <Button variant="contained" onClick={handleClose}>
                    No
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationModal;

const ConfirmationContext = createContext();

export const useConfirmation = () => useContext(ConfirmationContext);

const ConfirmationProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [handleConfirm, setHandleConfirm] = useState(null);

    const handleClickOpen = ({ title, content, onConfirm }) => {
        setOpen(true);
        setTitle(title);
        setDescription(content);
        setHandleConfirm(onConfirm);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleConfirmModal = () => {
        if (handleConfirm) {
            handleConfirm();
        }
        setOpen(false);
    };

    return (
        <ConfirmationContext.Provider
            value={{
                handleClickOpen,
                handleCloseModal,
                handleConfirmModal
            }}
        >
            {children}
            <ConfirmationModal
                open={open}
                handleConfirm={handleConfirmModal}
                handleClose={handleCloseModal}
                title={title}
                description={description}
            />
        </ConfirmationContext.Provider>
    );
};

export { ConfirmationProvider };
