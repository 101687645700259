import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import GuestGuard from 'utils/route-guard/GuestGuard';
import NavMotion from 'layout/NavMotion';

const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthResetPassword3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPassword3')));
const AuthForgotPassword3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));
const AuthResetPassword4 = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPassword4')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/configure-password',
            element: <AuthResetPassword3 />
        },
        {
            path: '/forgot-password',
            element: <AuthForgotPassword3 />
        },
        {
            path: '/reset-password/:resetCode',
            element: <AuthResetPassword4 />
        }
    ]
};

export default AuthenticationRoutes;
