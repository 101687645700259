// third-party
import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';

// project imports
// import axios from 'utils/axios';
import * as axiosPrev from 'axios';
import { dispatch } from '../index';
import _ from 'lodash';
import moment from 'moment';

const initialState = {
    error: null,
    events: [],
    loading: false
};

const accessToken = window.localStorage.getItem('accessToken');
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const slice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET EVENTS
        getEventsSuccess(state, action) {
            state.events = [...action.payload];
        },

        // ADD EVENT
        addEventSuccess(state, action) {
            state.events = [...state.events, action.payload];
        },

        // UPDATE EVENT
        updateEventSuccess(state, action) {
            const filterdEvents = [...state.events.filter((event) => event.id !== action.payload.id)];
            state.events = [...filterdEvents, action.payload];
        },

        // REMOVE EVENT
        removeEventSuccess(state, action) {
            state.events = [...state.events.filter((event) => event.id !== action.payload)];
        }
    }
});

// Reducer
export default slice.reducer;

const getParsedEvent = (events) => {
    const parsedEvents = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const event of events) {
        let service;
        if (event.services.length > 0) {
            service = { name: event.services[0].service_name, id: event.services[0].id };
        } else {
            service = null;
        }

        const parsedEvent = {
            // eslint-disable-next-line prefer-template
            id: event.sch_id + '',
            allDay: false,
            description: event.description,
            title: event.title,
            color: event.bg_color,
            textColor: event.fg_color,
            start: moment.utc(new Date(event.start_date_time)).local().toDate(),
            end: moment.utc(new Date(event.end_date_time)).local().toDate(),
            visitType: event.visit_type,
            status: event.status,
            location: event.locations[0],
            hour: event.duration_hour,
            minute: event.duration_minute,
            service,
            employee: event.employees,
            patient: event.patients
        };

        parsedEvents.push(parsedEvent);
    }

    return parsedEvents;
};

// ----------------------------------------------------------------------

export function getEvents(event) {
    const date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    if (!_.isEmpty(event)) {
        firstDay = event.firstDay;
        lastDay = event.lastDay;
    }

    return async () => {
        try {
            // const response = await axios.get('/api/calendar/events');
            const response = await axiosPrev.post(
                `${API_ENDPOINT}/api/scheduler/schedule/`,
                {
                    filters: {
                        start_date_time: format(firstDay, 'MM-dd-yyyy HH:mm:ss'),
                        end_date_time: format(lastDay, 'MM-dd-yyyy HH:mm:ss')
                    }
                },
                {
                    headers: {
                        SUBTYPE: 'LIST',
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            );
            const events = getParsedEvent(response.data.fields);
            dispatch(slice.actions.getEventsSuccess(events));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addEvent(event) {
    return async () => {
        const data = {
            fg_color: event.textColor,
            bg_color: event.color,

            employees: [...event.employee.map((emp) => emp.id)],
            locations: [event.location.id],
            patients: [...event.patient.map((pat) => pat.id)],
            services: [event.service.id],

            start_date_time: format(event.start, 'MM-dd-yyyy HH:mm:ss'),
            duration_hour: event.hour,
            duration_minute: event.minute,

            status: 'SCHEDULED',

            title: event.title,
            description: event.description,

            visit_type: event.visitType
        };
        // console.log(data);
        try {
            const response = await axiosPrev.post(`${API_ENDPOINT}/api/scheduler/schedule/`, data, {
                headers: {
                    SUBTYPE: 'CREATE',
                    Authorization: `Bearer ${accessToken}`
                }
            });
            const { fields } = response.data;

            // console.log(event)
            const event = getParsedEvent([fields]);

            dispatch(slice.actions.addEventSuccess(event[0]));
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateEvent(event) {
    return async () => {
        try {
            let data;

            const { update, eventId } = event;
            if (update.isEditing) {
                data = {
                    sch_id: Number(eventId),
                    fg_color: update.textColor,
                    bg_color: update.color,

                    employees: [...update.employee.map((emp) => emp.id)],
                    locations: [update.location.id],
                    patients: [...update.patient.map((pat) => pat.id)],
                    services: [update.service.id],

                    start_date_time: format(update.start, 'MM-dd-yyyy HH:mm:ss'),
                    duration_hour: update.hour,
                    duration_minute: update.minute,

                    status: update.status,

                    title: update.title,
                    description: update.description,

                    visit_type: update.visitType
                };
            } else {
                data = {
                    sch_id: Number(eventId),

                    start_date_time: format(update.start, 'MM-dd-yyyy HH:mm:ss')
                };
            }

            // console.log(event)
            dispatch(slice.actions.isLoading(true));

            const response = await axiosPrev.post(`${API_ENDPOINT}/api/scheduler/schedule/`, data, {
                headers: {
                    SUBTYPE: 'UPDATE',
                    Authorization: `Bearer ${accessToken}`
                }
            });

            const editedEvent = getParsedEvent([response.data.fields]);

            dispatch(slice.actions.updateEventSuccess(editedEvent[0]));
            dispatch(slice.actions.isLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeEvent(eventId) {
    return async () => {
        try {
            // const response = await axios.post('/api/calendar/events/remove', { eventId });
            const response = await axiosPrev.post(
                `${API_ENDPOINT}/api/scheduler/schedule/`,
                { sch_id: eventId },
                {
                    headers: {
                        SUBTYPE: 'DELETE',
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            );

            dispatch(slice.actions.removeEventSuccess(eventId));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
