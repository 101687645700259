import PropTypes from 'prop-types';
import { useMemo } from 'react';

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// project import
import useConfig from 'hooks/useConfig';
import Palette from './palette';
import Typography from './typography';

import componentStyleOverrides from './compStyleOverride';
import customShadows from './shadows';
import { light } from '@mui/material/styles/createPalette';

export default function ThemeCustomization({ children }) {
    const { borderRadius, fontFamily, navType, outlinedFilled, presetColor, rtlLayout } = useConfig();

    const theme = useMemo(() => Palette(navType, presetColor), [navType, presetColor]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const themeTypography = useMemo(() => Typography(theme, borderRadius, fontFamily), [theme, borderRadius, fontFamily]);
    const themeCustomShadows = useMemo(() => customShadows(navType, theme), [navType, theme]);

    // const themeOptions = useMemo(
    //     () => ({
    //         direction: rtlLayout ? 'rtl' : 'ltr',
    //         palette: theme.palette,
    //         mixins: {
    //             toolbar: {
    //                 minHeight: '48px',
    //                 padding: '16px',
    //                 '@media (min-width: 600px)': {
    //                     minHeight: '48px'
    //                 }
    //             }
    //         },
    //         typography: themeTypography,
    //         customShadows: themeCustomShadows
    //     }),
    //     [rtlLayout, theme, themeCustomShadows, themeTypography]
    // );

    const themeOptions = useMemo(
        () => ({
            direction: rtlLayout ? 'rtl' : 'ltr',
            palette: {
                ...theme.palette,
                primary: {
                    ...theme.palette.primary,
                    main: '#1B357C',
                    dark: '#1B357C',
                    light: '#E7E7E7',
                    A100: '#2950BC',
                    A200: '#6D8BDF'
                },
                secondary: {
                    ...theme.palette.secondary,
                    main: '#1B357C',
                    dark: '#1B357C',
                    light: '#E7E7E7'
                },
                background: {
                    ...theme.palette.background,
                    // default: '#E7E7E7',
                    // paper: '#E7E7E7',
                    light: '#E7E7E7'
                },
                text: {
                    ...theme.palette.text
                    // primary: '#1B357C',
                    // secondary: '#1B357C'
                }
            },
            typography: themeTypography,
            customShadows: themeCustomShadows,
            mixins: {
                toolbar: {
                    minHeight: '48px',
                    padding: '16px',
                    '@media (min-width: 600px)': {
                        minHeight: '48px'
                    }
                },
                ...theme.mixins
            }
        }),
        [rtlLayout, theme, themeCustomShadows, themeTypography]
    );

    const themes = createTheme(themeOptions);
    themes.components = useMemo(
        () => componentStyleOverrides(themes, borderRadius, outlinedFilled),
        [themes, borderRadius, outlinedFilled]
    );

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

ThemeCustomization.propTypes = {
    children: PropTypes.node
};
