import { useEffect, memo, useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from '../MenuList';
import LogoSection from '../LogoSection';
import MiniDrawerStyled from './MiniDrawerStyled';

import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'constant';

import { useDispatch, useSelector } from 'store';
import { onHover, openDrawer } from 'store/slices/menu';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const { drawerType } = useConfig();

    const logo = useMemo(
        () => (
            <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                <LogoSection />
            </Box>
        ),
        []
    );

    const drawer = useMemo(
        () => (
            // <PerfectScrollbar
            //     component="div"
            //     style={{
            //         height: '100vh',
            //         // height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
            //         paddingLeft: drawerOpen ? '16px' : 0,
            //         paddingRight: drawerOpen ? '16px' : 0,
            //         marginTop: matchDownMd ? 0 : 100
            //     }}
            // >
            <Box sx={{ height: '100%', mt: matchUpMd ? 11 : 0, px: drawerOpen ? '16px' : 0, overflowY: 'auto' }}>
                <MenuList lastItem={null} />
            </Box>
            // </PerfectScrollbar>
        ),

        [matchUpMd, drawerOpen, drawerType]
    );

    const mouseEnter = () => {
        dispatch(onHover(true));
    };

    const mouseLeave = () => {
        dispatch(onHover(false));
    };

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { md: 0 },
                position: 'absolute',
                top: 0,
                left: 0,
                width: matchUpMd ? drawerWidth : 'auto'
            }}
            aria-label="mailbox folders"
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
        >
            {matchDownMd ? (
                // responsive drawer
                <Drawer
                    variant={matchUpMd ? 'persistent' : 'temporary'}
                    anchor="left"
                    open={drawerOpen}
                    onClose={() => dispatch(openDrawer(!drawerOpen))}
                    sx={{
                        '& .MuiDrawer-paper': {
                            mt: matchDownMd ? 0 : 11,
                            zIndex: 1099,
                            width: drawerWidth,
                            background: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            borderRight: 'none'
                        }
                    }}
                    ModalProps={{ keepMounted: true }}
                    color="inherit"
                >
                    {matchDownMd && logo}
                    {drawer}
                </Drawer>
            ) : (
                // default drawer
                <MiniDrawerStyled variant="permanent" open={drawerOpen}>
                    {drawer}
                </MiniDrawerStyled>
            )}
        </Box>
    );
};

export default memo(Sidebar);
