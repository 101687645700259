import { memo } from 'react';

// project imports
import menuItem from 'menu-items';
import NavGroup from './NavGroup';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => <NavGroup item={menuItem} />;

export default memo(MenuList);
