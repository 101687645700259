import { createSlice } from '@reduxjs/toolkit';
import { authApi } from 'store/services/auth';
import jwtDecode from 'jwt-decode';

const initialState = {
    user: null,
    token: null,
    loginStatus: null,
    client: null,
    isLoggedIn: false,
    isAppLoaded: false,
    isIdle: false,
    userPermissions: [],
    userRole: null
};

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, { payload }) => {
            state.user = payload?.user || state.user;
            state.token = payload?.token || state.token;
            state.isLoggedIn = payload?.isLoggedIn || state.isLoggedIn;
            state.client = payload?.client || state.client;
        },
        logout: (state) => {
            state = initialState;
        },
        setClient: (state, { payload }) => {
            state.client = payload || state.client;
            state.user = {
                ...state.user,
                client_id: payload.id
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
                const tokenData = jwtDecode(payload.access);
                state.token = payload.access;
                state.loginStatus = tokenData.USER_ID_STATUS;
            })
            .addMatcher(authApi.endpoints.renewToken.matchFulfilled, (state, { payload }) => {
                state.token = payload.access;
            })
            .addMatcher(authApi.endpoints.getUser.matchFulfilled, (state, { payload }) => {
                state.user = payload.fields;
                state.isAppLoaded = true;
            })
            .addMatcher(authApi.endpoints.resetPassword.matchFulfilled, (state, { payload }) => {
                state.loginStatus = 5;
            });
    }
});

export const { setCredentials, logout, setClient } = slice.actions;

export const selectCurrentUser = (state) => state.auth.user;

export default slice.reducer;
