import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavCollapse from '../NavCollapse';
import NavItem from '../NavItem';

import { useSelector } from 'store';
import usePermission from 'hooks/usePermission';
import useAuth from 'hooks/useAuth';
import { PHYSICIAN_CLIENT } from 'constant';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const { user } = useAuth();
    const theme = useTheme();

    const { drawerOpen } = useSelector((state) => state.menu);

    const [currentItem, setCurrentItem] = useState(item);

    const { hasPermission } = usePermission();

    const items = currentItem.children
        ?.filter(
            (item) =>
                hasPermission(item.permissions) &&
                (user.is_superuser && user?.client_id === PHYSICIAN_CLIENT ? ['dashboard', 'settings', 'mail'].includes(item.id) : true)
        )
        .map((menu) => {
            switch (menu.type) {
                case 'collapse':
                    return <NavCollapse key={menu.id} menu={menu} level={1} parentId={currentItem.id} />;
                case 'item':
                    return <NavItem key={menu.id} item={menu} level={1} parentId={currentItem.id} />;
                default:
                    return (
                        <Typography key={menu.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });

    return (
        <>
            <List
                disablePadding={!drawerOpen}
                subheader={
                    currentItem.title &&
                    drawerOpen && (
                        <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                            {currentItem.title}
                            {currentItem.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {currentItem.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            {drawerOpen && <Divider sx={{ mt: 0.25, mb: 1.25 }} />}
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
