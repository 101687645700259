import { Autocomplete, Box, TextField } from '@mui/material';
import { PHYSICIAN_CLIENT } from 'constant';
import useAuth from 'hooks/useAuth';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'store';
import { useGetClientListQuery } from 'store/services/client';
import { setClient } from 'store/slices/auth';

function ClientSwitcher() {
    const { user } = useAuth();
    const dispatch = useDispatch();
    const { client } = useAuth();
    const navigate = useNavigate();

    const { data: clients, isFetching: clientsFetching } = useGetClientListQuery({
        filters: {
            status: 1,
            order: 'asc',
            order_col: 'name'
        }
    });

    const handleClientSetter = (cl) => {
        if (cl) {
            const temp = { id: cl.id, name: cl.name };
            localStorage.setItem('client', JSON.stringify(temp));
        }
    };

    const handleClientSwitch = (_e, val) => {
        if (val) {
            if (user?.is_superuser && val.id === PHYSICIAN_CLIENT) {
                navigate('/dashboard');
            }

            dispatch(setClient(val));
            handleClientSetter(val);
        }
    };

    useEffect(() => {
        if (!client) {
            // set client to the first one on the list
            if (clients?.fields?.[0]) {
                dispatch(setClient(clients?.fields?.[0]));
                handleClientSetter(clients?.fields?.[0]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clients]);

    return (
        <Box sx={{ width: 200 }}>
            <Autocomplete
                size="small"
                loading={clientsFetching}
                fullWidth
                value={client}
                getOptionLabel={(option) => option.name}
                options={clients?.fields || []}
                onChange={handleClientSwitch}
                clearIcon={null}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputLabelProps={{
                            style: { color: '#fff', marginTop: -10, marginLeft: -10 }
                        }}
                    />
                )}
            />
        </Box>
    );
}

export default ClientSwitcher;
