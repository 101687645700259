import React, { useRef, useEffect } from 'react';
import { useSendErrorLogMailMutation } from 'store/services/core';

function SendErrorLog({ hasError, error, info, renderCounter }) {
    const [sendErrorLogMail, { isLoading }] = useSendErrorLogMailMutation();

    const timeout = useRef(null);

    useEffect(() => {
        if (hasError && error) {
            const triggerErrorLog = async () => {
                try {
                    const payload = {
                        error: {
                            ErrorMessage: error?.message,
                            ErrorStack: error?.stack,
                            ComponentStack: info?.componentStack,
                            URL: window.location.href
                        }
                    };
                    sendErrorLogMail(payload);
                } catch (error) {
                    console.log({ error });
                }
            };

            timeout.current = setTimeout(() => {
                triggerErrorLog();
            }, 500);
        }

        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, [hasError, error, info]);

    return null;
}

export default SendErrorLog;
