import { IDLE_TIMEOUT_DURATION } from 'constant';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetStore } from 'store/actions';
import { useLogoutMutation } from 'store/services/auth';
// import { setSession } from 'store/services/api';
import { logout } from 'store/slices/auth';
import { resetDashboardState } from 'store/slices/dashboard';

// ==============================|| AUTH HOOKS ||============================== //

const useAuth = () => {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const [logoutApi, { isLoading }] = useLogoutMutation();

    const logoutAndReset = async () => {
        try {
            const res = await logoutApi({
                refresh: localStorage.getItem('refreshToken')
            });
            console.log({ res });
        } catch (error) {
            console.log(error);
        }

        localStorage.removeItem('client');
        localStorage.removeItem('lastActiveTime');

        dispatch(logout());
        dispatch(resetStore());
        dispatch(resetDashboardState());

        import('store/services/api').then(({ api, setSession }) => {
            dispatch(api.util.resetApiState());
            setSession(null, null);
        });
    };

    return {
        ...auth,
        user: {
            ...auth.user,
            client_id: auth.client?.id
        },
        logoutAndReset
    };
};

export default useAuth;
