import { createSlice } from '@reduxjs/toolkit';
import { patientApi } from 'store/services/patient';

const initialState = {
    patients: [],
    patientDetail: {}
};

const slice = createSlice({
    name: 'patient',
    initialState,

    extraReducers: (builder) => {
        builder.addMatcher(patientApi.endpoints.getPatientDetails.matchFulfilled, (state, { payload }) => {
            state.patientDetail = payload;
        });
    }
});

// export const {  } = slice.actions;

export default slice.reducer;
