import { api } from './api';

export const notificationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getNotifications: builder.query({
            query: (payload) => ({
                url: '/api/notification/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['NotificationList']
        }),
        updateNotifications: builder.mutation({
            query: (payload) => ({
                url: '/api/notification/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'UPDATE'
                }
            }),
            invalidatesTags: ['NotificationList', 'NotificationListInfinite']
        }),
        infiniteNotifications: builder.query({
            query: (payload) => ({
                url: '/api/notification/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            serializeQueryArgs: ({ endpointName }) => endpointName,
            merge: (currentCache, newData) => {
                if (newData?.pagination?.current_page > 1) {
                    return {
                        ...newData,
                        fields: [...(currentCache?.fields || []), ...(newData?.fields || [])]
                    };
                }

                return newData;
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
            providesTags: ['NotificationListInfinite']
        }),
        deleteNotification: builder.mutation({
            query: (payload) => ({
                url: '/api/notification/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'REMOVE'
                }
            }),
            invalidatesTags: ['NotificationList', 'NotificationListInfinite']
        })
    })
});

export const { useGetNotificationsQuery, useInfiniteNotificationsQuery, useUpdateNotificationsMutation, useDeleteNotificationMutation } =
    notificationApi;
