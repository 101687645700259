import { api } from './api';

export const authApi = api.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (body) => ({
                url: 'api/user/login/',
                method: 'POST',
                body
            })
        }),
        renewToken: builder.mutation({
            query: (body) => ({
                url: '/api/user/login/refresh/',
                method: 'POST',
                body
            })
        }),
        logout: builder.mutation({
            query: (body) => ({
                url: 'api/user/logout/',
                method: 'POST',
                body
            })
        }),
        getUser: builder.mutation({
            query: (body) => ({
                url: 'api/user/dashboard/',
                method: 'POST',
                body,
                headers: {
                    SUBTYPE: 'DETAIL'
                }
            })
        }),
        resetPassword: builder.mutation({
            query: (body) => ({
                url: 'api/user/resetpassword/',
                method: 'POST',
                body,
                headers: {
                    SUBTYPE: 'UPDATE'
                }
            })
        }),
        forgotPasswordMail: builder.mutation({
            query: (body) => ({
                url: 'api/user/mail/',
                method: 'POST',
                body,
                headers: {
                    SUBTYPE: 'reset'
                }
            })
        }),
        passCodeVerification: builder.mutation({
            query: (body) => ({
                url: 'api/user/resetexternal/',
                method: 'POST',
                body,
                headers: {
                    SUBTYPE: 'verify'
                }
            })
        }),
        forgotResetPassword: builder.mutation({
            query: (body) => ({
                url: 'api/user/resetexternal/',
                method: 'POST',
                body,
                headers: {
                    SUBTYPE: 'RESET'
                }
            })
        }),
        getUserClients: builder.query({
            query: (body) => ({
                url: 'api/user/users/',
                method: 'POST',
                body,
                headers: {
                    SUBTYPE: 'clientlist'
                }
            })
        })
    })
});

export const {
    useLoginMutation,
    useRenewTokenMutation,
    useLogoutMutation,
    useGetUserMutation,
    useResetPasswordMutation,
    useForgotPasswordMailMutation,
    usePassCodeVerificationMutation,
    useForgotResetPasswordMutation,
    useGetUserClientsQuery
} = authApi;
