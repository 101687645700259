import { api } from './api';

export const clientApi = api.injectEndpoints({
    endpoints: (builder) => ({
        // common
        getClientDetail: builder.query({
            query: (payload) => ({
                url: 'api/client/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'DETAIL'
                }
            }),
            providesTags: ['ClientDetail']
        }),
        updateClient: builder.mutation({
            query: (payload) => ({
                url: 'api/client/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'UPDATE'
                }
            }),
            invalidatesTags: ['ClientList', 'ClientDetail']
        }),
        getClientList: builder.query({
            query: (payload) => ({
                url: 'api/client/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['ClientList']
        }),
        deleteClient: builder.mutation({
            query: (payload) => ({
                url: 'api/client/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'DELETE'
                }
            }),
            invalidatesTags: ['ClientList']
        }),
        // history
        getClientHistory: builder.query({
            query: (payload) => ({
                url: 'api/client/activity/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            invalidatesTags: ['ClientHistory']
        }),
        getClientLogo: builder.query({
            query: (payload) => ({
                url: 'api/client/',
                method: 'POST',
                responseType: 'blob',
                body: payload,
                headers: {
                    SUBTYPE: 'LOGO'
                },
                responseHandler: (res) => res.blob().then((blob) => URL.createObjectURL(blob))
            }),
            providesTags: ['ClientLogo']
        })
    })
});

export const {
    useGetClientDetailQuery,
    useGetClientListQuery,
    useDeleteClientMutation,
    useGetClientHistoryQuery,
    useGetClientLogoQuery,
    useUpdateClientMutation
} = clientApi;
