import { PHYSICIAN_ROLE_ID } from 'constant';

const commonProps = {
    static: false,
    isDraggable: true,
    isResizable: true
};

export const defaultLayout = [
    // {
    //     i: 'greeter',
    //     x: 0,
    //     y: 0,
    //     w: 15,
    //     h: 5,
    //     minW: 15,
    //     maxW: 15,
    //     minH: 5,
    //     maxH: 5,
    //     ...commonProps,
    //     isResizable: false,
    //     params: {},
    //     permissions: []
    // },
    {
        i: 'totalPatientCount',
        x: 0,
        y: 0,
        w: 15,
        h: 5,
        minW: 12,
        maxW: 15,
        minH: 4,
        maxH: 6,
        ...commonProps,
        params: {
            type: 'total'
        },
        permissions: ['dashboard_patient_count_view']
    },
    {
        i: 'goalsMastered',
        x: 15,
        y: 0,
        w: 15,
        h: 5,
        minW: 12,
        maxW: 15,
        minH: 4,
        maxH: 6,
        ...commonProps,
        params: {},
        permissions: ['dashboard_patient_goal_mastered_view']
    },
    {
        i: 'averagePatientCount',
        x: 30,
        y: 0,
        w: 15,
        h: 5,
        minW: 12,
        maxW: 15,
        minH: 4,
        maxH: 6,
        ...commonProps,
        params: {},
        permissions: ['dashboard_average_patient_visit_view']
    },
    {
        i: 'scheduledNotes',
        x: 15,
        y: 0,
        w: 15,
        h: 5,
        minW: 12,
        maxW: 15,
        minH: 4,
        maxH: 6,
        ...commonProps,
        params: {},
        permissions: ['dashboard_therapist_scheduled_note']
    },
    {
        i: 'createdNotes',
        x: 30,
        y: 0,
        w: 15,
        h: 5,
        minW: 12,
        maxW: 15,
        minH: 4,
        maxH: 6,
        ...commonProps,
        params: {},
        permissions: ['dashboard_therapist_created_note']
    },
    {
        i: 'unsignedNotes',
        x: 45,
        y: 0,
        w: 15,
        h: 5,
        minW: 12,
        maxW: 15,
        minH: 4,
        maxH: 6,
        ...commonProps,
        params: {},
        permissions: ['dashboard_therapist_unsigned_note']
    },
    {
        i: 'therapyTreatmentCount_today',
        x: 0,
        y: 5,
        w: 30,
        h: 6.5,
        minW: 30,
        maxW: 60,
        minH: 10,
        maxH: 10,
        ...commonProps,
        params: {
            timeVariant: 'today'
        },
        permissions: ['dashboard_treatment_view', 'dashboard_evaluation_view']
    },
    {
        i: 'therapyTreatmentCount_week',
        x: 30,
        y: 5,
        w: 30,
        h: 6.5,
        minW: 30,
        maxW: 60,
        minH: 10,
        maxH: 10,
        ...commonProps,
        params: {
            timeVariant: 'week'
        },
        permissions: ['dashboard_treatment_view', 'dashboard_evaluation_view']
    },
    {
        i: 'therapyTreatmentCount_month',
        x: 0,
        y: 15,
        w: 30,
        h: 6.5,
        minW: 30,
        maxW: 60,
        minH: 10,
        maxH: 10,
        ...commonProps,
        params: {
            timeVariant: 'month'
        },
        permissions: ['dashboard_treatment_view', 'dashboard_evaluation_view']
    },
    {
        i: 'therapistReport',
        x: 30,
        y: 15,
        w: 30,
        h: 12,
        minW: 30,
        maxW: 60,
        minH: 8,
        maxH: 16,
        ...commonProps,
        params: {},
        permissions: ['dashboard_therapist_leaderboard_view']
    },
    {
        i: 'presentVsAbsent',
        x: 0,
        y: 25,
        w: 20,
        h: 14,
        minW: 20,
        maxW: 20,
        minH: 14,
        maxH: 14,
        ...commonProps,
        params: {},
        permissions: ['dashboard_student_attendance_view']
    },
    {
        i: 'physicianPatientCount',
        x: 0,
        y: 0,
        w: 15,
        h: 5,
        minW: 12,
        maxW: 15,
        minH: 4,
        maxH: 6,
        ...commonProps,
        params: {},
        permissions: [],
        roles: [PHYSICIAN_ROLE_ID]
    },
    {
        i: 'physicianPrescriptionCount',
        x: 15,
        y: 0,
        w: 15,
        h: 7,
        minW: 15,
        maxW: 15,
        minH: 7,
        maxH: 7,
        ...commonProps,
        params: {},
        permissions: [],
        roles: [PHYSICIAN_ROLE_ID]
    }
];

// Any widgets that are planned to be
// conditionally injected to the dom should
// be seperated out into their own individual configs
export const therapyTreatmentTable = {
    i: 'therapyTreatment-table',
    x: 0,
    y: 0,
    w: 30,
    h: 18,
    minW: 30,
    maxW: 60,
    minH: 18,
    maxH: 18,
    ...commonProps,
    params: {},
    permissions: ['dashboard_treatment_view', 'dashboard_evaluation_view']
};

export const therapyTreatmentGraph = {
    i: 'therapyTreatment-graph',
    x: 30,
    y: 0,
    w: 30,
    h: 18,
    minW: 30,
    maxW: 60,
    minH: 18,
    maxH: 18,
    ...commonProps,
    params: {},
    permissions: ['dashboard_treatment_view', 'dashboard_evaluation_view']
};
